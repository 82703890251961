// $border: 1px solid red;
$border: none;

.W-Wrapper {
  border: $border;
  margin-left: 20px;

  .W-Videos-Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .W-NoVideos {
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      padding-top: 20px;
      color: rgb(107, 132, 143);

      .GD-NoVideos-Day {
        color: grey;
        text-decoration: underline;
      }
    }

    .No-videos-text {
      text-align: center;
      font-weight: bold;
      font-size: 25px;
    }
  }

  .W-AddVideo-Btn-Div {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #W-AddVideo-Btn {
    width: 180px;
    background-color: #e25601;
    color: white;
    padding: 5px 0;
    border-radius: 0px;
    font-size: medium;
  }
}

.Wor-user-videos-Wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .Wor-NoVideos {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    padding-top: 20px;
    color: rgb(107, 132, 143);

    .GD-NoVideos-Day {
      color: grey;
      text-decoration: underline;
    }
  }
}

.react-player {
  min-width: 400px;
  min-height: 225px;
}

.MuiDialog-paperScrollPaper {
  max-height: unset !important;
  overflow: hidden !important;
}

.MuiDialog-paperWidthSm {
  max-width: unset !important;
}

.W-AddVideo-Wrapper {
  border: $border;
  padding-bottom: 30px;

  .W-Title-Container {
    border: $border;
    margin: 10px 0px;

    .W-Title-Text {
      border: $border;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      padding: 10px 0px;
    }

    .W-Title-Input {
      border: $border;
    }
  }

  .W-Author-Container {
    border: $border;
    margin: 10px 0px;

    .W-Author-Text {
      border: $border;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      padding: 10px 0px;
    }

    .W-Author-Input {
      border: $border;
    }
  }

  .W-Content-Container {
    border: $border;
    margin: 10px 0px;

    .W-Content-Text {
      border: $border;
      font-size: 20px;
      padding: 10px 0px;
    }

    .W-Content-Input {
      border: $border;
    }
  }

  .W-SelectImage-Container {
    border: $border;
    margin: 10px 0px;

    .W-SelectImage-Text {
      border: $border;
      font-size: 20px;
      padding: 10px 0px;
    }

    .W-SelectImage-Input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        // width: 180px;
        background-color: #e9e9e9;
        padding: 8px 20px;
        border-radius: 0px;
        border-radius: 2px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }
    }

    #W-SelectImage-Button {
      width: 180px;
      background-color: #e9e9e9;
      padding: 8px 0;
      border-radius: 0px;
    }
  }

  .W-BottomBtns-Container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #W-Back-Button {
      width: 100px;
      background-color: #e9e9e9;
      padding: 8px 0;
      border-radius: 0px;
    }

    #W-Publish-Button {
      width: 180px;
      background-color: #e25601;
      padding: 10px 0;
      color: white;
      border-radius: 0px;
      margin-right: 8%;
    }

    @media only screen and (max-width: 960px) {
      #W-Publish-Button {
        margin-right: 0;
      }
    }
  }
}

.Wor-Video-spotifyIcon-div {
  margin-left: 10px;
  display: flex;
  position: relative;
  .Wor-Video-spotifyIcon-btn {
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 3px 5px;
    background: #ffffff;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.3);

    .Wor-Video-spotifyIcon {
      width: 25px;
    }
    .Wor-Video-spotifyIcon-text {
      margin-left: 5px;
      font-weight: bold;
    }
  }

  .Wor-Video-spotifyIcon-btn:hover {
    background-color: black;
    color: white;
  }

  .Wor-Video-delete-btn
  {
    position: absolute;
    right:0px;

    .Wor-Video-delete-icon
    {
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .Wor-Video-delete-icon:hover
    {
      color:red;
    }
  }

}
