.sidebar-list div::-webkit-scrollbar
{
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

/* ::-webkit-scrollbar-track {
    background: rgb(9, 10, 10);
    display: none;
    opacity:0;
    border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
    transition: 1s all ease;
    display: block;
    opacity: 1;
} */
.sidebar-list div::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;

    /* color: rgb(178, 195, 202); */
    box-shadow: inset 0 0 0 10px;
    /* opacity: 0; */
}

/* .sidebar-list div::-webkit-scrollbar-thumb:hover {
    color:black; 
} */

.sidebar-list div:hover {
    color: rgba(207, 207, 207, 1);
  }

  .sidebar-list div {
    color: rgba(207, 207, 207, 0.315);
    transition: 0.3s all ease;
  }


/* .sidebar-list div::-webkit-scrollbar-thumb
{

} */

.sidebar-list div::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}

.sidebar-list div::-webkit-scrollbar-corner {
	background-color: transparent;
}




  
  /* Track
  
  Handle
 
  
  Handle on hover
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */