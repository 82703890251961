.user-data-search-button
{
    background: #E25601 !important;
    border-radius: 0px !important;
    color:white !important;
    height:36px !important;
}

.user-specific-change-profile-picture
{
    display: inline-block;
    vertical-align: top;
    padding-top: 75px;
    padding-bottom: 10px;
    margin-left: 0px;
}

.groups-participating-div
{
    margin-bottom: 20px;
}

.groups-participating-name
{
    margin-left: 10px;
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: gray;
    font-size: 18px;
}

.user-specific-delete-button-div
{
    margin-left: 20px;
}

#user-specific-delete-button
{
    font-size:20px;
    text-transform: none;
    font-weight: bold;
}

.csv-download-wrapper
{
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.csv-download-button
{
    background: #225B76 !important;
    transition: 0.3s all ease;

}

.csv-download-button:hover
{
    background: #153240 !important;

}

.disabled-button {
    opacity: 0.5;
}

