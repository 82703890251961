.song-time-left-player {
  position: absolute;
  right: 0px;
}
.song-previous-icon-player {
  font-size: 70px !important;
}
.song-play-icon-player {
  font-size: 90px !important;
}
.song-pause-icon-player {
  font-size: 90px !important;
}
.song-skip-icon-player {
  font-size: 70px !important;
}

.song-title-player {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.song-fake-zero {
  display: inline-block;
  transform: translateX(3px);
}

.custom-file-upload {
  background-color: #e9e9e9;
  padding: 8px 20px;
  border-radius: 0px;
  border-radius: 2px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.Main-Listen-skeleton {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  margin-bottom: 10px;
}

.Listen-skeleton-circle-div {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Listen-skeleton-lines {
  width: 85%;
}

.Listen-skeleton-line {
  height: 35px !important;
}

.listen-item-wrapper
{
  transition: all 0.5s !important;
}

.listen-item-wrapper:hover
{
  transform: scale(1.1);
}

#favourite-icon-div
{
  float: right;
}