// $border: 1px solid red;
$border: none;

.Login-Main-Wrapper {
  border: $border;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/background1-min2.jpg");
  background-position: center;
  background-size: cover;

  .Login-Main-Container {
    border: $border;
    width: 75%;

    .Login-Logo-Container {
      border: $border;
      text-align: center;
      margin-bottom: 50px;

      .Login-Logo {
        width: 250px;
        height: 135px;
      }
    }

    .Login-Form-Container {
      border: $border;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .Login-Container {
        border: $border;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .MuiInputBase-root,
        .MuiFormLabel-root {
          color: white !important;
        }
        .MuiInput-underline::before {
          border-bottom: 1px solid white;
        }
        .MuiInput-underline::after {
          border-bottom: 2px solid white;
        }

        .Login-Email {
          width: 60%;
          margin: 10px 0;
        }

        .Login-Password {
          width: 60%;
          margin: 10px 0;
        }

        .login-error {
          // margin: 10px 0;
          // align-self: left;
          // width: 60%;
          font-weight: bold;
          color: red;
        }

        .Login-Forgot-Password {
          width: 60%;
          margin: 10px 0;
          text-align: start;
          margin-left: 30px;
          color: #e4f7ff;
          cursor: pointer;
          transition: color 0.7s ease;
        }

        .Login-Forgot-Password:hover {
          text-decoration: underline;
          color: #022635;
        }

        .Login-Btn {
          width: 60%;
          margin: 10px 0;
        }

        #Login-Button {
          background-color: white;
          color: #225b76;
          padding: 15px 0;
          font-weight: bold;
          border-radius: 0px;
          font-size: medium;
          transition: background 0.3s ease;
        }

        #Login-Button:hover {
          background: #022635;
          color: #ffffff;
        }
      }

      .Login-NewAccount-Cont {
        border: $border;
        border-left: 1px dashed white;
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .Login-NewAccount-Text {
          width: 60%;
          margin: 15px 0;
          text-align: start;
          margin-left: 30px;
          color: white;
        }

        .Login-CreateAcc-Btn {
          width: 60%;
          margin: 10px 0;
          #CreatAccBtn {
            background-color: white;
            color: #225b76;
            font-weight: bold;
            padding: 15px 0;
            border-radius: 0px;
            font-size: medium;
            transition: background 0.5s ease;
          }

          #CreatAccBtn:hover {
            background: #022635;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 930px) {
  .Login-Form-Container {
    flex-wrap: wrap;
  }

  .Login-Main-Container {
    width: 90% !important;
  }

  .Login-NewAccount-Cont {
    border-left: none !important;
  }
}
