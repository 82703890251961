.route-main {
  margin-left: 290px;
  /* margin-left: 50px; */
  margin-top: 20px;
  margin-right: 40px;
  min-width: 300px;
}

@media only screen and (max-width: 1211px) {
  .route-main {
    margin-top: 220px;
  }
}

@media only screen and (max-width: 791px) {
  .route-main {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .route-main {
    margin-left: 50px;
  }
}
