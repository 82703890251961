.date-comp-wrapper {
  margin-left: 240px;
  /* margin-left: 0px; */
  padding-left: 30px;
  color: #e25601;
  font-size: 30px;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.date-comp-icon-button {
  color: #e25601 !important;
  padding: 5px !important;
  vertical-align: -webkit-baseline-middle;
}

.date-comp-icon {
  font-size: 50px !important;
  padding: 0px !important;
}

.custome-date {
  cursor: pointer;
}

.custome-date:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .date-comp-wrapper {
    margin-left: 0px;
  }
}
