// $border: 1px solid red;
$border: none;

.JO-Wrapper {
  border: $border;
  display: flex;
  flex-direction: row;

  .JO-NoJournal {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
  }

  .JO-Container {
    border: $border;
    width: 100%;
    height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    

    .JO-Title {
      font-size: 24px;
      font-weight: bold;
    }
    .JO-EditJournal {
      margin-top:10px;
      font-weight:640;
      color:#022635;
      cursor: pointer;
      transition: color,background 0.3s ease;
    }


    .JO-EditJournal:hover {
      // background: rgb(182, 182, 238);
      text-decoration: underline;
      color:grey;
    }

    .JO-Content {
      border: $border;
      flex: 1;
      min-height: 100px;
      overflow: auto;
      border-top: 2px solid rgb(218, 217, 217);
      border-bottom: 2px solid #555;

      .jo-content-no-journal
      {
        margin-top:20px;
        display: block;
        color:rgb(39, 93, 116);
      }

      .jo-content-no-journal-date
      {
        color:grey;
        text-decoration:underline;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        cursor: pointer;
      }
    }

    .JO-Edit-Content {
      flex: 1;
      // border: $border;
      min-height: 100px;
      overflow:auto;
    }

    #JO-Button {
      width: 180px;
      background-color: #e25601;
      color: white;
      padding: 12px 0;
      border-radius: 0px;
      font-size: medium;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width:1211px) {
      .JO-Container {
        height: calc(100vh - 530px);
    }
  }

  @media only screen and (max-width:765px) {
    .JO-Container {
      height: calc(100vh - 570px);
  }
  }

  @media only screen and (max-width:599) {
    .JO-Container {
      height: calc(100vh - 0px);
    }
  }


}


.journal-loader-wrraper
{
  padding-top:200px;
  position: relative;
}
