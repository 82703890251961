// $border: 1px solid red;
$border: none;

.SG-Wrapper {
  // border: $border;
  font-family: arial;
  .SG-GroupNames-Container {
    // border: $border;
    overflow: auto;
    margin-bottom: 10px;
    max-height: calc(100vh - 110px);
    padding-right: 20px;
    &::-webkit-scrollbar {
      width: 7px;
      height: 2px;
    }

    *:hover::-webkit-scrollbar {
      width: 10px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #fffdfd;
      border-radius: 5px;
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(202, 202, 202);
      border-radius: 5px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(172, 172, 172);
    }

    .SG-GroupNames-headerRow {
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SG-MailIcon-div {
        margin-right: 15px;
        position: relative;
        .SG-MailIcon {
          width: 50px;
          cursor: pointer;
        }
        .SG-noti-dot {
          width: 12px;
          height: 12px;
          background-color: #e25601;
          border-radius: 50%;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }

      #SG-createGroupBtn {
        width: 180px;
        background-color: #e25601;
        color: white;
        padding: 5px 0;
        border-radius: 0px;
        font-size: medium;
      }
    }

    .SG-Groups-loader {
      height: calc(100vh - 430px);
      // background: #225b76;
      padding-top: calc(50% - 50px);
      justify-items: center;
      align-items: center;
      align-content: center;
    }

    .SG-NoGroup-text {
      margin-top: 40px;
      font-size: 20px;
      font-family: arial;
      color: rgb(107, 132, 143);
    }

    .SG-NoGroup-anchor {
      color: #e25601;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .SG-NoGroup-anchor:hover {
      text-decoration: underline;
      color: #022635;
    }

    .SG-GroupNames-list {
      overflow: auto;

      .SG-GroupNames-Search {
        width: 95.5%;
        font-size: 16px;
        padding-top: 13px;
        padding-bottom: 13px;
        padding-left: 10px;
      }
    }

    .GN-HeaderRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .GN-Noti-Text {
        font-weight: bold;
        font-size: 20px;
      }
      .SG-GN-goBack {
        cursor: pointer;
        font-weight: bold;
        margin-right: 10px;
        font-size: 20px;
      }
      .SG-GN-goBack:hover {
        text-decoration: underline;
      }
    }

    .SG-GN-Group-Container:hover {
      background-color: #9898987c;
    }

    .SG-GN-Group-Container {
      border-bottom: 2px solid #989898;
      display: flex;
      flex-direction: row;
      padding: 15px 0;
      cursor: pointer;

      .SG-GN-noti-div {
        width: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 6px 0;

        .SG-GN-noti-dot {
          width: 15px;
          height: 15px;
          background-color: #e25601;
          border-radius: 50%;
        }
      }

      .SG-GN-details-div {
        // border: $border;
        padding-left: 20px;
        width: calc(100% - 20px);

        .SG-GN-name {
          font-weight: bold;
          font-size: 18px;
        }
        .SG-GN-name-admin {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          .SG-GN-name-admin-name,
          .SG-GN-name-admin-remove {
            font-weight: bold;
            font-size: 18px;
          }

          .SG-GN-name-admin-remove {
            color: #e25601;
            cursor: pointer;
            margin-right: 10px;
          }
        }
        .SG-GN-members {
          font-style: italic;
          font-weight: 640;
        }
        .SG-GN-msg {
          color: #989898;
        }
        .SG-GN-buttons {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin-top: 10px;

          #SG-GN-buttons-accept {
            background-color: #2c7178;
            color: white;
            width: 45%;
            padding: 10px 0;
          }
          #SG-GN-buttons-reject {
            background-color: #3babb6;
            color: white;
            width: 45%;
            padding: 10px 0;
          }
        }
      }
    }

    .rodal-dialog {
      width: 59% !important;
      height: 75% !important;
      display: flex;
      flex-direction: column;
    }

    .rodal {
      z-index: 1300 !important;
    }

    @media only screen and (max-width: 600px) {
      .rodal-dialog {
        width: 80% !important;
      }
    }
  }

  .SG-GroupChat-Container {
    border: $border;
    background-color: #f5f4f4;
    margin-bottom: 10px;
    // height: 98vh;
    // padding: 20px 35px;
    // overflow: auto;

    .SG-GroupChat-Group-Loader {
      height: calc(100vh - 320px);
      min-height: 350px;
      width: 100%;
      position: relative;
      background: #022635;

      .SG-GroupChat-Group-Loader-Image {
        width: 256px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
      }

      .SG-GroupChat-Group-Loader-Text {
        position: relative;
        top: calc(50% + 128px);
        text-align: center;
        color: white;
        font-size: 20px;
      }

      @keyframes hvr-back-pulse {
        50% {
          opacity: 0.1;
        }
      }
      .hvr-back-pulse {
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translate(-50%, -50%, 0);
        transform: perspective(1px) translate(-50%, -50%, 0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 3s;
        -webkit-transition-property: opacity;
        transition-property: opacity;

        -webkit-animation-name: hvr-back-pulse;
        animation-name: hvr-back-pulse;
        -webkit-animation-duration: 4s;
        animation-duration: 3s;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        opacity: 1;
      }
      // .hvr-back-pulse:hover, .hvr-back-pulse:focus, .hvr-back-pulse:active {

      // }
    }

    .SG-Groups-loader {
      height: calc(100vh - 430px);
    }

    .SG-GroupChat-Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 110px);
      margin: 10px 35px;
      margin-right: 10px;

      // margin-right:0px;

      .SG-GroupChat-header {
        border: $border;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .SG-GroupChat-name {
          font-weight: bold;
          font-size: 22px;
        }
      }

      .SG-GroupChat-Messages {
        border: $border;
        flex: 1;
        overflow: auto;
        // scroll-behavior: smooth;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #ffffff;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(184, 182, 182);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          cursor: pointer;
        }

        .MessageDateHeader-main-div
        {
          margin-top:10px;
          color: #949090;
          text-align:center;
        }

        .Msg-wrapper {
          margin-right: 15px;

          .Msg-Container {
            background-color: white;
            border-radius: 22px;
            width: auto;
            max-width: 50%;
            padding: 15px;
            margin: 8px 15px;
            position: relative;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.5);

            .Msg-SenderName {
              font-weight: bold;
            }

            .Msg-Content {
              .Msg-Date-Me
              {
                padding-left:20px;
                color:#eeeeee;
                font-size:11px;
                font-weight: 640;
              }
              .Msg-Date-Others
              {
                position: absolute;
                bottom:19px;
                right:10px;
                color:#2c7178;
                font-size:11px;
                font-weight: 640;
              }
            }

            .Msg-triangle-left {
              position: absolute;
              left: -15px;
              bottom: 5px;
              width: 0;
              height: 0;
              border-top: 13px solid transparent;
              border-right: 26px solid white;
              border-bottom: 13px solid transparent;
            }

            .Msg-triangle-left-admin {
              position: absolute;
              left: -15px;
              bottom: 5px;
              width: 0;
              height: 0;
              border-top: 13px solid transparent;
              border-right: 26px solid rgb(246, 248, 219);
              border-bottom: 13px solid transparent;
            }

            .Msg-triangle-right {
              position: absolute;
              right: -15px;
              bottom: 5px;
              width: 0;
              height: 0;
              border-top: 13px solid transparent;
              border-left: 26px solid #2c7178;
              border-bottom: 13px solid transparent;
            }
          }
        }
      }

      .SG-GroupChat-input-Container {
        border: $border;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;

        .SG-GroupChat-WriteComment-Input-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          .SG-GroupChat-WriteComment-Input {
            background-color: white;
          }
        }
        .SG-GroupChat-WriteComment-Btn-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 10%;
        }

        #SG-GroupChat-WriteComment-Btn {
          background-color: #e25601;
          color: white;
          width: 100%;
          min-width: 45px;
          height: 100%;
          border: none;
          border-radius: 0px;
          font-size: medium;
          outline: none;
          cursor: pointer;
        }
      }
    }

    .SG-GroupSetting-Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 110px);
      margin: 10px 35px;
      margin-right: 10px;
      // padding-right: 15px;
      margin-bottom: 0px;

      .SG-GroupSetting-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .SG-GroupSetting-name {
          font-weight: bold;
          font-size: 22px;
          cursor: default;

          .SG-GroupSetting-Editname {
            font-weight: 500;
            color: #989898;
            cursor: pointer;
            margin-left: 20px;
            transition: 0.3s all ease;
          }

          .SG-GroupSetting-Editname:hover {
            color: #636363;
          }
        }
      }

      .SG-GroupSetting-membersList {
        flex: 1;
        overflow: auto;
        // margin-right:15px;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #ffffff;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(184, 182, 182);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          cursor: pointer;
        }

        .SG-GroupSetting-member-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin: 5px 0;
          // margin-right: 15px;
          padding: 10px 15px;

          .SG-GroupSetting-member-details {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 90%;

            .SG-GroupSetting-member-name {
              font-weight: 640;
              font-size: 18px;
              width: 49%;
              min-width: 200px;
            }
            .SG-GroupSetting-member-info {
              font-size: 18px;
              color: #989898;
              width: 49%;
              min-width: 200px;
            }
          }

          .SG-GroupSetting-member-remove {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }

      .SG-GroupSetting-GroupOptions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        // padding-top:20px;

        .SG-GroupSetting-Option-div {
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: space-evenly;
          width: 40%;
          padding-top: 20px;
          padding-bottom: 10px;

          .SG-GroupSetting-Option-icon {
            background-color: #e25601;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            cursor: pointer;
          }

          .SG-GroupSetting-Option-text {
            margin-left: 10px;
            font-weight: 700;
            font-size: 20px;
          }
        }
      }
    }

    .SG-AddMember-Wrapper {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      max-height: calc(100vh - 110px);
      padding-top: 10px;
      margin: 10px 35px;
      margin-bottom: 30px;

      .SG-AddMember-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .SG-AddMember-name {
          font-family: Arial, sans-serif;
          font-weight: 700;
          font-size: 22px;
        }
        .SG-AddMember-back {
          font-family: Arial, sans-serif;
          font-weight: 700;
          font-size: 22px;
          cursor: pointer;
        }
        .SG-AddMember-back:hover {
          text-decoration: underline;
        }
      }

      .SG-AddMember-List-div {
        // flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: auto;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 10px;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #ffffff;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(184, 182, 182);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
          cursor: pointer;
        }

        // .SG-AddMember-List-row {
        //   display: flex;
        //   justify-content: space-between;
        //   flex-wrap: wrap;
        //   align-items: center;

        .SG-AddMember-member {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;
          margin-left: 5px;
          margin-right: 5px;
          width: 45%;
          min-width: 200px;

          .SG-AddMember-member-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: 0.3s all ease;

            color: black;
            font-weight: 640;
            font-size: 18px;
            font-family: Arial, sans-serif;
          }

          .SG-AddMember-member-Addicon-enable {
            color: #989898;
            cursor: pointer;
            transition: 0.4s ease all;
          }

          .SG-AddMember-member-Addicon-enable:hover {
            color: #444444;
            cursor: pointer;
          }

          .SG-AddMember-member-Addicon-disable {
            color: #cfcfcf !important;
            cursor: default;
          }

          // .SG-AddMember-member-Addicon {
          //   // margin-left: 45%;
          // }
        }
        // }
      }
    }
  }
}

.NewGroupModal-rodal-header {
  text-align: center;
  font-size: 20px;
  font-family: arial;
  font-weight: 640;
}

.NewGroupModal-rodal-content-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 90%;
}

.NewGroupModal-rodal-button-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

#NewGroupModal-rodal-newGroup-button {
  background-color: rgb(245, 244, 244);
  color: #225b76;
  // padding: 15px 0;
  font-weight: bold;
  // border-radius: 0px;
  font-size: medium;
  transition: background 0.3s ease;
}

#NewGroupModal-rodal-newGroup-button:hover {
  background: #022635;
  color: #ffffff;
}

.NewGroupModal-rodal-error-wrapper {
  margin-top: 20px;
  color: red;
  font-size: 18px;
  font-weight: 640px;
}

// NEW GROUP MODAL CSS

.NewGroupModal-membersList {
  // flex: 1;
  overflow: auto;
  background-color: #e9e9e9;
  min-width: 165px;
  width: 100%;
  // margin-top: 10px;
  // margin-right:15px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(184, 182, 182);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  .NewGroupModal-member-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
    margin-right: 15px;

    .NewGroupModal-member-details {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 90%;

      .NewGroupModal-member-name {
        font-weight: 640;
        font-size: 18px;
        width: 85%;
        min-width: 200px;
        margin-left: 10px;
      }
    }

    .NewGroupModal-member-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.NewGroupModal-input-div {
  margin-top: 10px;
  width: 100%;
  .NewGroupModal-input {
    width: calc(100% - 9px);
    padding: 7px 0 5px 7px;
    font-size: 17px;
  }
}

// rodal width height

// .rodal-dialog {
//   width: 59% !important;
//   height: 75% !important;
//   display: flex;
//   flex-direction: column;
// }

// .rodal {
//   z-index: 1300 !important;
// }

// @media only screen and (max-width: 600px) {
//   .rodal-dialog {
//     width: 80% !important;
//   }
// }

@keyframes loader1 {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.logo-wrapper-loader1 {
  width: 170px;
  height: 120px;
  background-color: #022635;
  padding: 40px;
  margin: auto;

  -webkit-box-shadow: -1px 32px 14px 5px rgba(0, 35, 99, 0.63);
  -moz-box-shadow: -1px 32px 14px 5px rgba(0, 35, 99, 0.63);
  box-shadow: -1px 32px 14px 5px rgba(0, 35, 99, 0.63);
}

.logo-loader1 {
  animation-name: loader1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.text-loader1 {
  margin-top: 20px;
  color: white;
  font-family: "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: 600;
}

.dots-loader-wrapper {
  // width:100px;
}

.text-loader1 .LoaderDots_root__qnubN {
  display: -webkit-inline-box !important;
  margin-left: 5px;
}
