@import "loaders.css/src/animations/ball-pulse-sync.scss";

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
}

// $border: 1px solid red;
$border: none;

.FV-Wrapper {
  border: $border;

  @media (max-width: 1211px) {
    padding-top: 80px;
  }

  .FV-NoArticle {
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    font-size: 25px;
    color: rgb(107, 132, 143);

    .FV-NoArticle-Day {
      color: grey;
      text-decoration: underline;
    }
  }

  .FV-Admin-Controls-div {
    .FV-Edit-Delete-Add-Options {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .FV-Edit-Delete-Article-div {
        display: flex;

        .FV-Edit-Text {
          cursor: pointer;
        }

        .FV-Edit-Text:hover {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }

  .FV-RightContainer {
    border: $border;
    width: 100%;

    .FV-ImageContainer {
      width: 12vw;
      height: 12vw;
      // background-image: url("../../assets/dummyDP.jpg");
      background-position: center;
      background-size: cover;
      margin-right: 2%;
      margin-bottom: 2%;
      float: left;
    }

    .FV-Title {
      font-size: 25px;
      font-weight: bold;
    }

    .FV-Author {
      margin-top: 5px;
      margin-bottom: 15px;
      color: #757575;
    }

    .FV-Content {
      // margin-top: 10px;
    }
  }

  #FV-Edit-Button {
    width: 180px;
    background-color: #e25601;
    color: white;
    padding: 5px 0;
    border-radius: 0px;
    font-size: medium;
  }
}

.FV-AddArticle-Wrapper {
  border: $border;
  padding-bottom: 30px;

  .FV-Title-Container {
    border: $border;
    margin: 10px 0px;

    .FV-Title-Text {
      border: $border;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      padding: 10px 0px;
    }

    .FV-Title-Input {
      border: $border;
    }
  }

  .FV-Author-Container {
    border: $border;
    margin: 10px 0px;

    .FV-Author-Text {
      border: $border;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      font-size: 20px;
      padding: 10px 0px;
    }

    .FV-Author-Input {
      border: $border;
    }
  }

  .FV-Content-Container {
    border: $border;
    margin: 10px 0px;

    .FV-Content-Text {
      border: $border;
      font-size: 20px;
      padding: 10px 0px;
    }

    .FV-Content-Input {
      border: $border;
    }
  }

  .FV-SelectImage-Container {
    border: $border;
    margin: 10px 0px;

    .FV-SelectImage-Text {
      border: $border;
      font-size: 20px;
      padding: 10px 0px;
    }

    .FV-SelectImage-Input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      input[type="file"] {
        display: none;
      }

      .FV-RemoveImage {
        color: red;
        cursor: pointer;
      }

      .FV-RemoveImage:hover {
        text-decoration: underline;
        font-weight: bold;
      }

      .custom-file-upload {
        // width: 180px;
        background-color: #e9e9e9;
        padding: 8px 20px;
        border-radius: 0px;
        border-radius: 2px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }
    }

    #FV-SelectImage-Button {
      width: 180px;
      background-color: #e9e9e9;
      padding: 8px 0;
      border-radius: 0px;
    }
  }

  .FV-BottomBtns-Container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #FV-Back-Button {
      width: 100px;
      background-color: #e9e9e9;
      padding: 8px 0;
      border-radius: 0px;
    }

    #FV-Publish-Button {
      width: 180px;
      background-color: #e25601;
      padding: 10px 0;
      color: white;
      border-radius: 0px;
      margin-right: 8%;
    }

    @media only screen and (max-width: 960px) {
      #FV-Publish-Button {
        margin-right: 0;
      }
    }
  }
}
