.companions-new-request-header {
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
}

.companions-request-name {
  padding-left: 10px;
  font-size: 18px;
  display: inline-block;
}

.companions-request-button {
  margin-left: 10px !important;
  width: 150px !important;
  max-width: calc(50% - 10px);
  border-radius: 0px !important;
}

.other-companions-request-button
{
  margin-left: 10px !important;
  width: 150px !important;
  border-radius: 0px !important;
  
}

.companions-request-confirm-button {
  background: #e25601 !important;
  color: white !important;
}

.companions-request-cancel-button {
  background-color: #e9e9e9 !important;
  color: black !important;
}

.companions-request-decline-button {
  background: #d4d4d4 !important;
  color: black !important;
}

.companions-request-send-button-wrapper {
  display: inline-block;
  position: absolute;
  right: 0px;
}

.companions-request-send-button {
  margin-left: 10px !important;
  width: 150px !important;
  /* max-width: calc(50% - 10px); */
  border-radius: 0px !important;
}

.companions-mycomp-header {
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
  position: relative;
}

.companions-find-button {
  background: #e25601 !important;
  color: white !important;
  border-radius: 0px !important;
  position: absolute !important;
  right: 0px !important;
}

.companions-back-button {
  background: #e25601 !important;
  color: white !important;
  border-radius: 0px !important;
  position: absolute !important;
  right: 0px !important;
  height: 25px !important;
}

.companions-mycomp-name {
  padding-left: 10px;
  font-size: 18px;
  display: inline-block;
  padding-bottom: 18px;
}

.companions-find-search-field {
  display: block;
  width: calc(100% - 74px);
  margin-right: 10px !important;
}

.companions-find-search-field .MuiInputBase-root {
  border-radius: 0px !important;
}

.companions-find-search-button {
  background: #e25601 !important;
  color: white !important;
  border-radius: 0px !important;
}

.companions-finder-loader-wrapper {
  height: calc(100vh - 420px);
  width: 100%;
  /* background: rgb(241, 239, 239); */
  background: #022635;

  position: relative;
}

.companions-search-loader-image {
  width: 128px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.companions-search-loader-text {
  position: relative;
  top: calc(50% + 64px);
  text-align: center;
  color: white;
  font-size: 20px;
}

@keyframes hvr-back-pulse {
  50% {
    opacity: 0.1;
  }
}
.hvr-back-pulse {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translate(-50%, -50%, 0);
  transform: perspective(1px) translate(-50%, -50%, 0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;

  -webkit-animation-name: hvr-back-pulse;
  animation-name: hvr-back-pulse;
  -webkit-animation-duration: 4s;
  animation-duration: 3s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 1;
}


.companion-list-item-wrapper
{
  transition: all 0.3s ease;
}

.companions-mycomp-list
{
  overflow: hidden;
}
