.admin-route-main {
  margin-left: 276px;
  margin-top: 20px;
  margin-right: 26px;
  min-width: 300px;
}

/* @media only screen and (max-width: 1011px) {
    .route-main {
      margin-top: 220px;
    }
  } */

@media only screen and (max-width: 736px) {
  .admin-route-main {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .admin-route-main {
    margin-left: 50px;
  }
}
