.track-my-progress-header {
  font-size: 20px;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
}

.track-list-date {
  padding-left: 20px;
  font-size: 18px;
  display: inline-block;
  font-weight: bold;
}
.track-list-congrats {
  padding-left: 20px;
  font-size: 18px;
  display: inline-block;
  font-weight: bold;
}
.track-list-message {
  padding-left: 20px;
  font-size: 14px;
  display: inline-block;
}

.track-list-comp-progress {
  padding-left: 10px;
  font-weight: bold;
}

.track-list-comp-name {
  padding-left: 10px;
  font-weight: 500;
  font-size: 17px;
}

.track-my-progress-line {
  position: absolute;
  width: 2px;
  height: 30px;
  background: #dadada;
  top: 61px;
  left: 39px;
}

.track-my-progress-cong-line {
  position: absolute;
  width: 2px;
  height: 50%;
  background: #dadada;
  top: calc(50% + 23px);
  left: 39px;
}

.track-my-progress-line-cong-up {
  position: absolute;
  width: 2px;
  height: calc(50% - 38px);
  background: #dadada;
  top: 15px;
  left: 39px;
}

.track-my-progress-shield-img {
  width: 165px;
}

.track-my-progress-StartChallenge-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.track-my-progress-StartChallenge-text {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

#track-my-progress-StartChallenge-Button {
  min-width: 180px;
  margin-top: 20px;
  background-color: #215c75;
  color: white;
  border-radius: 0;
}

#track-my-progress-endChallengeBtn {
  color: white;
  border-radius: 0;
  background-color: #e25601;
  margin-left: 20px;
}

.EndProgressModal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.EndProgressModal-text {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color:black;
}

#EndProgressModal-continueBtn {
  background-color: #2c7178;
  color: white;
  min-width: 270px;
  margin-top: 30px;
}

#EndProgressModal-restartBtn {
  color: white;
  background-color: #3babb6;
  min-width: 270px;
  margin-top: 15px;
}
