.no-data-wrapper
{   
    padding-top:40px;
    font-weight: 640;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    color:rgb(107, 132, 143);
    position: relative;
}

.no-data-inner-div
{
    text-align: center;
    position: absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%
}

/* .W-NoVideos {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    padding-top:20px;
    color:rgb(107, 132, 143);

    .GD-NoVideos-Day {
      color:grey;
      text-decoration:underline;
    }
  }
} */