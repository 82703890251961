// $border: 1px solid red;
$border: none;

.EP-Wrapper {
  .EP-FormContainer {
    .Ep-InputContainer {
      border: $border;
      margin-bottom: 20px;
    }

    .EP-Input {
      width: 90%;
      border: none;
      border-bottom: 3px solid black;
      font-size: 17px;
      padding-left: 15px;
      outline: none;
    }

    .EP-InputLabel {
      border: $border;
      font-size: 17.5px;
      width: 80%;
      margin-top: 5px;
      margin-left: 15px;
    }
  }

  .EP-OptionsContainer {
    border: $border;

    .EP-DPContainer {
      .EP-DPContainer1 {
        border: $border;
        margin: 10px 0;

        .EP-changeDPText {
          margin-bottom: 15px;
        }

        input[type="file"] {
          display: none;
        }

        .custom-file-upload {
          background-color: #e9e9e9;
          padding: 8px 20px;
          border-radius: 0px;
          border-radius: 2px;
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
        }
      }

      .EP-DPContainer2 {
      }
    }

    .EP-OptContainer1 {
      border: $border;
      margin: 20px 0;
      .EP-RadioGroup {
        margin: 10px 0;
      }
    }

    .Ep-OptContainer2 {
      border: $border;
      margin: 20px 0;
      .EP-RadioGroup {
        margin: 10px 0;
      }
    }
  }

  #EP-SaveButton {
    width: 180px;
    background-color: #e25601;
    color: white;
    padding: 12px 0;
    border-radius: 0px;
    font-size: medium;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  #EP-UploadBtn {
    margin-left: 25px;
    font-size: 14px;
    // width: 10px;
    // background-color: #e9e9e9;
    // border-radius: 0px;
  }
}
