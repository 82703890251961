.forgot-text-field
{
    max-width:400px;
    width:100%;
}

.forgot-otp-button-wrapper
{
    margin-top:30px;
    margin-bottom:10px;
}

.forgot-otp-button {
    /* background-color: white; */
    color: #225b76 !important;
    /* padding: 15px 0; */
    font-weight: bold !important;
    border-radius: 0px !important;
    font-size: medium !important;
    transition: background 0.3s ease !important;
    text-transform: none !important;
  }

  .forgot-otp-button:hover {
    background: #022635 !important;
    color: #ffffff !important;
  }

  .forgot-password-fields-wrapper
  {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
