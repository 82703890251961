.dashboard-card-typography {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  /* left:calc(50% - 65px); */
  font-size: 24px;
  color: white;
  font-family: Helvetica, sans-serif;
  text-align: center;
  width: 100%;
}
