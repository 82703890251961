.header-toolbar {
  position: relative;
  display: contents;
}
.header-avatar-div {
  width: 160px !important;
  height: 160px !important;
  margin: 20px;
  margin-top: 40px;
  display: inline-block !important;
  position: relative;
}

.header-avatar {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 791px) {
  .header-avatar-div {
    width: 128px !important;
    height: 128px !important;
  }
  .header-Badge {
    width: 40px !important;
  }
  .Badge21-text {
    bottom: 10px !important;
    left: 13px !important;
    font-size: 11px !important;
  }
  .Badge90-text {
    bottom: 10px !important;
    left: 13px !important;
    font-size: 11px !important;
  }
}

.header-Badge21-div {
  position: absolute;
  z-index: 10;
  bottom: 0;
}

.Badge21-text {
  font-weight: bold;
  color: #296e75;
  position: absolute;
  bottom: 11px;
  left: 18px;
  font-size: 13px;
}

.Badge90-text {
  font-weight: bold;
  color: #e25601;
  position: absolute;
  bottom: 11px;
  left: 18px;
  font-size: 13px;
}

.header-Badge {
  width: 50px;
}

.header-Badge90-div {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
}

.header-user-name {
  color: black;
  font-weight: bold !important;
  font-size: 22px !important;
  vertical-align: top;
  padding-top: 65px;
  display: inline-block;
}

.header-tracker-div {
  /* background: grey; */
  position: absolute;
  right: 80px;
  margin-top: 20px;
  display: inline-block;
}

.header-tracker-div-wrapper {
  /* position: relative; */
  min-width: 390px;
}

@media only screen and (max-width: 1211px) {
  .header-tracker-div {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 791px) {
  .header-tracker-div {
    position: relative !important;
    width: 96%;
    margin: 2vw;
    right: 0;
    left: 0;
  }
}

.header-tracker-upper-div-1 {
  display: inline-block;
  color: black;
  font-size: 30px;
  font-weight: 500;
}

.header-tracker-upper-div-2 {
  display: inline-block;
  position: absolute;
  right: 0px;
}

#header-tracker-upper-div-2-button {
  background: #e25601;
  color: white;
  border-radius: 0px;
  width: 220px;
  font-weight: bold;
  height: 47px;
  font-size: 16px;
  transition: 0.3s all ease;
}

.header-tracker-circle-indicator {
  /* padding: 20px; */
  width: 50px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #225b76;
  display: inline-block;
  position: absolute;
  top: 62px;
  font-size: 20px;
  border-radius: 30px;
}

.header-tracker-middle-div {
  padding-top: 40px;
  padding-bottom: 20px;
}

.header-tracker-bottom-div {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.header-tracker-bottom-div-forget
{

  font-size: 15px;

}

@media only screen and (max-width: 791px) {
  /* .header-tracker-middle-div .MuiLinearProgress-bar 
  {
    transform: translateX(0) !important;
  } */
  .header-tracker-middle-div .MuiLinearProgress-root {
    width: 100%;
  }

  .header-tracker-middle-div {
    position: relative;
    padding-bottom: 40px;
  }
  .header-tracker-upper-div-1 {
    font-size: 28px;
  }
  .header-tracker-bottom-div {
    font-size: calc(4.4vw);
  }

  .header-tracker-circle-indicator {
    top: 22px;
    font-size: 20px;
    border-radius: 30px;
    padding: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    left: calc(60% - 50px);
    width: 20px;
  }
}

.header-hamburger {
  width: 100px;
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .header-hamburger {
    display: unset !important;
  }
}
