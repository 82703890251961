// $border: 1px solid red;
$border: none;

.Comments-Main-Wrapper {
  margin-bottom: 100px;

  .CM-NoComments {
    text-align: center;
    font-weight: bold;
    padding-top: 20px;
    font-size: 25px;
    color: rgb(107, 132, 143);
  }

  .CM-NoComments-Day {
    color: grey;
    text-decoration: underline;
  }

  .CM-Wrapper {
    // border: $border;

    .reply-container {
      margin-top: 25px;
      margin-left: 100px;
    }

    .view-replies {
      display: inline;
    }

    .view-replies,
    .hide-replies {
      color: #225b76;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 960px) {
  .Comments-Main-Wrapper {
    margin-bottom: 150px;
  }
}

.cm-Wrapper {
  border: $border;

  .Main-Comment-Div {
    border: $border;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 15px;

    .Main-Comment-avatar-div {
      border: $border;
      width: 100px;
      display: flex;
      flex-direction: row;
    }
    .Main-Comment-content-div {
      border: $border;
      width: 100%;

      .Main-Comment-username {
        font-size: 18px;
        font-weight: bold;
      }

      .Main-Comment-icons-div {
        margin-top: 10px;
        margin-left: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .Main-Comment-icons-Inner-div {
          border: $border;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin: 5px 0px;

          .Main-Comment-heart-icon-div {
            border: $border;
            display: flex;
            flex-direction: row;
            align-items: center;

            .heart-icon {
              width: 21px;
              height: 19px;
              cursor: pointer;
            }

            .Main-Comment-heart-icon-text {
              margin-left: 5px;
              // cursor: pointer;
            }

            // .Main-Comment-heart-icon-text:hover {
            //     text-decoration: underline;
            // }
          }

          .Main-Comment-reply-icon-div {
            border: $border;
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer !important;

            .reply-icon {
              width: 19px;
              height: 17px;
              border: $border;
            }

            .Main-Comment-reply-icon-text {
              border: $border;
              margin-left: 5px;
              text-decoration: inherit;
            }

            .Main-Comment-reply-icon-text:hover {
              text-decoration: underline;
            }
          }
        }

        .Main-Comment-Remove-Comment-div {
          font-weight: bold;
          color: #e25601;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .CM-WriteComment-Container {
    width: 100% !important;
  }
}

.CM-WriteComment-Container {
  background-color: #e9e9e9;
  margin-left: -48px;
  margin-right: -26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  padding-top: 20px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 241px);

  .CM-WriteComment-div {
    .CM-WriteComment-Input-div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .CM-WriteComment-Input {
        background-color: white;
        border-radius: 0px !important;
      }

      .CM-WriteComment-Input .MuiInputBase-root {
        border-radius: 0px !important;
        height: 52px;
      }
    }
    .CM-WriteComment-Btn-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  #CM-WriteComment-Btn {
    background-color: #e25601;
    color: white;
    padding: 12px 0;
    border-radius: 0px;
    font-size: medium;
  }
}

.Main-Comment-skeleton {
  display: flex;
  flex-direction: row;
  margin: 10px 0;

  .Comment-skeleton-circle-div {
    width: 8%;
    width: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
    .Comment-skeleton-circle {
      width: 5vh !important;
      height: 5vh !important;
    }
  }

  .Comment-skeleton-lines {
    width: 92%;
    .Comment-skeleton-line {
      height: 1vh;
    }
  }
}
