// $border: 1px solid red;
$border: none;

.Signup-Main-Wrapper {
  border: $border;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/background2-min-4.jpg");
  background-position: center;
  background-size: cover;

  .Signup-Main-Container {
    border: $border;
    width: 75%;

    .Signup-Logo-Container {
      border: $border;
      text-align: center;
      margin-bottom: 10px;
      .Signup-Logo {
        width: 250px;
        height: 135px;
      }
    }

    .Signup-Heading-Text {
      font-size: 27px;
      margin-top: 10px;
      margin-bottom: 28px;
      color: white;
      text-align: center;
      cursor: default;
    }

    .Signup-Form-Container {
      border: $border;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .signup-error {
        color: red;
        text-align: center;
        font-weight: bold;
      }

      .MuiInputBase-root,
      .MuiFormLabel-root {
        color: white !important;
      }
      .MuiInput-underline::before {
        border-bottom: 1px solid white;
      }
      .MuiInput-underline::after {
        border-bottom: 2px solid white;
      }

      .Signup-NewAccount-Cont {
        border: $border;
        border-left: 1px dashed white;
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;

        .Signup-NewAccount-Text {
          width: 60%;
          margin: 15px 0;
          text-align: start;
          margin-left: 30px;
        }

        .Signup-CreateAcc-Btn {
          width: 60%;
          margin: 10px 0;
          #CreatAccBtn {
            background-color: white;
            color: #225b76;
            font-weight: bold;
            padding: 15px 0;
            border-radius: 0px;
            font-size: medium;
          }
        }
      }
    }

    .Signup-Bottom-Btn {
      border: $border;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      #Signup-Button {
        background-color: white;
        color: #225b76;
        font-weight: bold;
        padding: 15px 0;
        border-radius: 0px;
        font-size: medium;
        width: 30%;
        transition: background 0.5s ease;
      }

      @media only screen and (max-width: 960px) {
        #Signup-Button {
          width: 70%;
        }
      }

      // .Signup-Btn-Grid {
      //   display: flex;
      //   justify-content: center;
      //   flex-direction: column;

      #Signup-Button:hover {
        background: #022635;
        color: #ffffff;
      }

      .already-account {
        text-align: center;
        width: 358px;
        // background: green;
        color: #aecedd;
        margin-top: 20px;
        cursor: default;
      }

      .already-account-anchor {
        color: #e4f7ff;
        cursor: pointer;
        transition: color 0.7s ease;
      }
      .already-account-anchor:hover {
        color: #022635;
      }
      // }
    }
  }
}

.Signup1-Email {
  border: $border;
  width: 70%;
  margin: 20px auto;
}

.Signup1-OderNumber {
  border: $border;
  width: 70%;
  margin: 10px auto;
}

.Signup2-CityState,
.Signup2-Birthday,
.Signup2-Password,
.Signup2-Email,
.Signup2-Name {
  border: $border;
  width: 70%;
  margin: 10px auto;
}

.Signup2-Birthday {
  .Signup2-DateInput {
    width: 100%;
    border: none;
    border-bottom: 1px solid white;
    font-size: 17px;
    outline: none;
    color: white;
    background-color: transparent;
    padding-bottom: 4px;
    padding-top: 23px;
  }
  .Signup2-DateInput::placeholder {
    color: white;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

.Signup3-HeadingQuestion {
  text-align: start;
  color: white;
}

.Signup4-HeadingQuestion {
  text-align: center;
  color: white;
}



.Signup3-RadioGroup {
  margin: 10px 0;
}
